import { mapState, mapActions, mapGetters } from 'vuex'
import Zepto from 'zepto-webpack'
import Events from '@/configuration/Events'

import PreloadedAssets from '@/configuration/PreloadedAssets'
import paymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'
import { loadAssets } from '@/common/loader/assets'
import { MethodDescriptor } from '@/common/model/PaymentMethod'

import KryptonLoadingAnimation from '@/host/components/controls/KryptonLoadingAnimation'
import SmartFormCardIcons from '@/host/components/smartform/CardIcons'
import SmartFormRadioButton from '@/host/components/smartform/RadioButton'
import SmartButtonSkeleton from '@/host/components/smartbutton/Skeleton'
import { ApplePayMixin } from '@/host/components/mixins/ApplePay'
import { SmartFormClickMixin } from '@/host/components/mixins/SmartFormClick'

export const SmartButtonMixin = {
  mixins: [ApplePayMixin, SmartFormClickMixin],
  components: {
    KryptonLoadingAnimation,
    SmartFormCardIcons,
    SmartFormRadioButton,
    SmartButtonSkeleton
  },
  data() {
    return {
      hover: false,
      icon: null,
      hasNoCards: false,
      isFallback: false
    }
  },
  computed: {
    ...mapGetters([
      'isMethodAvailable',
      'isMethodAvailableInDna',
      'isSinglePaymentButton',
      'hasSeveralPaymentMethods',
      'hasPaymentMethods',
      'hasSmartButton',
      'numOfPaymentMethods',
      'hasCardMethodAvailable',
      'getCustomPaymentMethodLabel',
      'getCustomPaymentMethodIcon',
      'isApplePaySimulator',
      'isFallbackSmartButtonVisible',
      'isSelectedMethod',
      'hasValidToken',
      'translate'
    ]),
    ...mapState([
      'amountLabel',
      'isUnitaryTest',
      'disabledForm',
      'disabledCardForm',
      'allIFramesReady'
    ]),
    ...mapGetters({
      paymentMethods: 'effectivePaymentMethodList'
    }),
    ...mapState({
      cardBrands: state => state.smartForm.cardBrands,
      errorCode: state => state.error.errorCode,
      forceSkeleton: state => state.smartButton.forceSkeleton,
      activeMethod: state => state.smartForm.activeMethod,
      activeMethodMetadata: state => state.smartForm.activeMethodMetadata,
      buttonConfig: state => state.form.smartButton.button,
      spinnerVisible: state => state.form.smartButton.button.spinnerVisible,
      paymentDone: state => state.smartForm.paymentDone
    }),
    styles() {
      const styles = {}
      styles.button = {}
      if (this.hover) {
        styles.button.border = this.buttonConfig.$hover.border
      }
      return styles
    },
    dynamicClasses() {
      return {
        'kr-smart-button--spbtn': this.isSinglePaymentButton,
        'kr-smart-button--fallback': this.isFallback,
        'kr-hover': this.hover,
        [this.methodClass]: true,
        'kr-disabled':
          (this.isDisabled && !this.isApplePay) ||
          (this.isDisabled &&
            !this.isMethodAvailableInDna(this.cleanPaymentMethod)) ||
          this.disabledForm,
        'kr-loading': this.isLoading,
        'kr-apple-pay': this.isApplePay,
        'kr-apple-pay--simulator': this.isApplePay && this.isApplePaySimulator
      }
    },
    methodDescriptor() {
      return MethodDescriptor.fromString(this.paymentMethod)
    },
    methodClass() {
      return `kr-${this.cleanPaymentMethod.toLowerCase()}`
    },
    defaultMethodLabel() {
      // Specific translation for smartbutton
      const key = `smartbutton_method_${this.cleanPaymentMethod.toLowerCase()}`
      if (this.translate(key) !== key) return this.translate(key)
      // Use the Pay with + method format
      return `${this.translate('smartbutton_label')} ${
        this.singleBrandLabel ||
        paymentMethodsConf.labels[this.cleanPaymentMethod] ||
        this.cleanPaymentMethod
      }`
    },
    title() {
      return this.isDisabled
        ? this.translate('smartbutton_title_disabled')
        : null
    },
    isApplePay() {
      return this.cleanPaymentMethod === 'APPLE_PAY'
    },
    isLoading() {
      return !this.errorCode && this.isPaymentActive
    },
    showLoadingAnimation() {
      return this.spinnerVisible && this.isLoading
    },
    showRadioButton() {
      return this.isSinglePaymentButton
    },
    isPaymentActive() {
      return (
        this.activeMethod === this.cleanPaymentMethod &&
        this.activeMethodMetadata === this.metadata
      )
    },
    cleanPaymentMethod() {
      return this.methodDescriptor.name
    },
    metadata() {
      return this.methodDescriptor.metadata ?? null
    },
    isDisabled() {
      return (
        !this.allIFramesReady ||
        !this.isMethodAvailableInDna(this.cleanPaymentMethod) ||
        this.paymentDone ||
        this.disabledForm ||
        (this.cleanPaymentMethod === 'CARDS' && this.disabledCardForm) ||
        (this.paymentMethod === 'CARDS' && this.cardBrands.length === 0) ||
        this.hasNoCards
      )
    }
  },
  watch: {
    isVisible: 'parentVisibility'
  },
  async created() {
    if (!this.isUnitaryTest) {
      const icons = await loadAssets()
      this.icon =
        icons.paymentMethods.regular[this.cleanPaymentMethod] ||
        PreloadedAssets.regular.card
    }
  },
  mounted() {
    this.parentVisibility(this.isVisible)
  },
  methods: {
    ...mapActions(['openMethod', 'selectMethod']),
    async triggerMethod(method, $event) {
      // Single payment button with fallback button will always be selected by default
      // The button is only visual, the payment logic is called from the payment button
      if (this.isSinglePaymentButton && this.isFallbackSmartButtonVisible)
        return

      if (this.isSinglePaymentButton) {
        this.selectMethod(method)
        return
      }

      const action = this.paymentMethod.startsWith('CARDS')
        ? 'openPopin'
        : 'beforePaymentStart'
      if (await this.interruptsExecution(this.paymentMethod, action))
        return false

      // If we click on the + button, the button is disabled or the payment done, we
      // avoid the method selection
      const $target = Zepto($event.target)
      if (this.isDisabled || this.isPlusLabel($target)) return

      if (!this.isApplePay) this.loading = true

      this.openMethod(method)
    },
    isPlusLabel($target) {
      return $target.hasClass('kr-extra-brands')
    },
    parentVisibility(val) {
      if (this.$el?.parentElement)
        this.$el.parentElement.style.display = val ? 'block' : 'none'
    },
    setNumOfBrands(num) {
      this.hasNoCards = num === 0
    }
  }
}
