<template lang="pug">
  section.kr-sp-panel-wrapper(v-show="!hasDetached")
    label.kr-sp-panel-wrapper__label {{ headerLabel | capitalize | colon(language) }}
    .kr-sp-panel-content(ref="content")
      SplitPaymentPanel(
        :installment-options="options"
        v-on:option-selected="onOptionSelected"
      )
    SplitPaymentPanelSeparator
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

import SplitPaymentPanel from '@/host/components/smartform/splitPayment/Panel'
import SplitPaymentPanelSeparator from '@/host/components/smartform/splitPayment/PanelSeparator'
import DetachableMixin from '@/host/components/mixins/Detachable'

/**
 * This wrapper is responsible for fetching data from store and provide
 * the expected class instances to the SplitPaymentPanel.
 *
 * It also responsible for handling the possible detachment in case
 * the element .kr-payment-schedule is found in DOM.
 *
 * @since KJS-4363
 * @see KJS-4368 | Add label and SplitPaymentPanelSeparator
 */
export default {
  name: 'SplitPaymentPanelWrapper',
  components: {
    SplitPaymentPanel,
    SplitPaymentPanelSeparator
  },
  mixins: [DetachableMixin],
  props: {
    attach: {
      type: [Boolean, String, Element],
      default: true
    }
  },
  computed: {
    ...mapGetters([
      'isSplitPaymentEnabled',
      'isSplitPaymentPanelOutside',
      'installmentOptions',
      'translate'
    ]),
    ...mapState(['language']),
    options() {
      return this.installmentOptions
    },
    render() {
      return this.isSplitPaymentEnabled && !!this.options
    },
    headerLabel() {
      return this.translate('split_payment_panel_header')
    }
  },
  mounted() {
    if (this.isSplitPaymentPanelOutside) {
      this.detachTo('.kr-payment-schedule')
    }
  },
  methods: {
    ...mapActions(['selectInstallmentOption']),
    onOptionSelected(id) {
      this.selectInstallmentOption(id)
    }
  }
}
</script>
