import { Collection } from '@/common/model/'

const PRIVATE_CONSTRUCTOR_KEY = Symbol()

/**
 * Used to ensure a correct schedule is passed to SplitPaymentTimeline
 * component. It cannot be instanciated with new operator. Only the factory
 * should be used in order to validate the data but also sanitize it and sort
 * installments by date ascending.
 *
 * @since KJS-4362
 */
export default class InstallmentSchedule extends Collection {
  // Factory -------------------------------------------------------------------
  static create(installmentList) {
    if (installmentList instanceof InstallmentSchedule) return installmentList
    if (!Array.isArray(installmentList)) {
      throw new TypeError(
        `InstallmentSchedule::create: Invalid parameter, installments is expected to be an Array`
      )
    }

    this._validate(installmentList)

    return new InstallmentSchedule(
      this._sanitize(installmentList),
      PRIVATE_CONSTRUCTOR_KEY
    )
  }

  static _validate(installmentList) {
    if (installmentList.length < 2 || installmentList.length > 4)
      throw new Error(
        `InstallmentSchedule.create: Invalid number of installment: ${installmentList.length}; expect value to be between 2 and 4`
      )
    for (const idx in installmentList) {
      const installment = installmentList[idx]
      const { date, amount } = installment
      const hasValidDate =
        date instanceof Date ||
        typeof date === 'number' ||
        (typeof date === 'string' && !Number.isNaN(Date.parse(date)))
      if (!hasValidDate)
        throw new TypeError(
          `InstallmentSchedule.create: Invalid parameter at index [${idx}], property date: ${date}`
        )
      const hasValidAmount =
        typeof amount === 'number' ||
        (typeof amount === 'string' && !Number.isNaN(parseInt(amount)))
      if (!hasValidAmount)
        throw new TypeError(
          `InstallmentSchedule.create: Invalid parameter at index [${idx}], property amount: ${amount}`
        )
    }
  }

  static _sanitize(installmentList) {
    return installmentList
      .map(installment => {
        return {
          date: new Date(installment.date),
          amount: parseInt(installment.amount)
        }
      })
      .sort((a, b) => a.date - b.date)
  }

  // Instance ------------------------------------------------------------------
  constructor(installmentList, $key) {
    if ($key !== PRIVATE_CONSTRUCTOR_KEY) {
      throw new Error(
        `InstallmentSchedule: cannot create instance using new operator`
      )
    }
    super(installmentList)
  }

  firstInstallment() {
    return this.collection[0]
  }

  subsequentInstallments() {
    return this.collection.slice(1)
  }

  slice(...args) {
    return new InstallmentSchedule(
      this.collection.slice(...args),
      PRIVATE_CONSTRUCTOR_KEY
    )
  }
}
