import regeneratorRuntime from 'regenerator-runtime' // NECESSARY to use async
import { ensureUniqId } from '@/common/util/svg'

export const loadAssets = async () => {
  // Regular
  const regularBrandKadeosCulture = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/kadeos_culture.svg'
  )
  const regularBrandKadeosGift = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/kadeos_gift.svg'
  )
  const regularBrandTuya = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/tuya.svg'
  )
  const regularBrandCarnet = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/carnet.svg'
  )
  const regularBrandConecs = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/conecs.svg'
  )
  const regularBrandDiners = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/diners.svg'
  )
  const outlineBrandDiners = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/diners_outline.svg'
  )
  const regularBrandSodexo = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/sodexo.svg'
  )
  const regularBrandEdenredCo = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/edenred_co.svg'
  )
  const regularBrandChqDej = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/chq_dej.svg'
  )
  const regularBrandApetiz = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/apetiz.svg'
  )
  const regularBrandMastercard = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/mastercard.svg'
  )
  const regularBrandMastercardDebit = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/mastercard_debit.svg'
  )
  const regularBrandAmex = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/amex.svg'
  )
  const regularBrandCb = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/cb.svg'
  )
  const regularBrandMaestro = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/maestro.svg'
  )
  const regularBrandBanContact = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/bancontact.svg'
  )
  const regularBrandVisa = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/visa.svg'
  )
  const outlineBrandVisa = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/visa_outline.svg'
  )
  const regularBrandVisaDebit = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/visa_debit.svg'
  )
  const regularBrandVisaElectron = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/visa_electron.svg'
  )
  const regularBrandVpay = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/vpay.svg'
  )
  const regularBrandCabalDebit = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/cabal.svg'
  )
  const regularBrandDcc = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/planet_dcc.svg'
  )
  const regularBrandCmr = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/cmr.svg'
  )
  const regularBrandDiscover = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/discover.svg'
  )
  const regularBrandOsb = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/osb.svg'
  )
  const regularBrandPrv = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/prv_smart_card.svg'
  )
  const regularBrandPrvOpt = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/prv_opt.svg'
  )
  const regularBrandECarteBleue = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/e-cartebleue.svg'
  )
  const regularBrandJcb = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/jcb.svg'
  )
  const regularBrandNaranja = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/naranja.svg'
  )
  const regularBrandAura = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/aura.svg'
  )
  const regularBrandElo = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/elo.svg'
  )
  const regularBrandHiper = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/hiper.svg'
  )
  const regularBrandHipercard = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/hipercard.svg'
  )
  const regularBrandPrvBdp = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/prv_bdp.svg'
  )
  const regularBrandPrvSocredo = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/brands/prv_socredo.svg'
  )
  // Errors
  const errorBrandKadeosCulture = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/kadeos_culture.svg'
  )
  const errorBrandKadeosGift = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/kadeos_gift.svg'
  )
  const errorBrandTuya = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/tuya.svg'
  )
  const errorBrandCarnet = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/carnet.svg'
  )
  const errorBrandConecs = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/conecs.svg'
  )
  const errorBrandDiners = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/diners.svg'
  )
  const errorBrandSodexo = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/sodexo.svg'
  )
  const errorBrandEdenredCo = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/edenred_co.svg'
  )
  const errorBrandChqDej = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/chq_dej.svg'
  )
  const errorBrandApetiz = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/apetiz.svg'
  )
  const errorBrandMastercard = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/mastercard.svg'
  )
  const errorBrandMastercardDebit = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/mastercard_debit.svg'
  )
  const errorBrandAmex = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/amex.svg'
  )
  const errorBrandCb = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/cb.svg'
  )
  const errorBrandMaestro = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/maestro.svg'
  )
  const errorBrandBanContact = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/bancontact.svg'
  )
  const errorBrandVisa = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/visa.svg'
  )
  const errorBrandVisaDebit = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/visa_debit.svg'
  )
  const errorBrandVisaElectron = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/visa_electron.svg'
  )
  const errorBrandVpay = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/vpay.svg'
  )
  const errorBrandCabalDebit = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/cabal.svg'
  )
  const errorBrandDcc = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/planet_dcc.svg'
  )
  const errorBrandCmr = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/cmr.svg'
  )
  const errorBrandDiscover = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/discover.svg'
  )
  const errorBrandOsb = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/osb.svg'
  )
  const errorBrandPrv = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/prv_smart_card.svg'
  )
  const errorBrandECarteBleue = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/e-cartebleue.svg'
  )
  const errorBrandJcb = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/jcb.svg'
  )
  const errorBrandNaranja = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/naranja.svg'
  )
  const errorBrandAura = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/aura.svg'
  )
  const errorBrandElo = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/elo.svg'
  )
  const errorBrandHiper = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/hiper.svg'
  )
  const errorBrandHipercard = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/hipercard.svg'
  )
  const errorBrandPrvBdp = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/prv_bdp.svg'
  )
  const errorBrandPrvSocredo = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/brands/prv_socredo.svg'
  )
  // Fields (error)
  const errorCard = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/fields/card.svg'
  )
  const errorExpiry = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/fields/expiry.svg'
  )
  const errorSecurityCode = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/fields/securityCode.svg'
  )
  const errorIdentityDocumentNumber = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/fields/idCard.svg'
  )
  const errorCardHolderName = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/fields/cardName.svg'
  )
  const errorCardHolderMail = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/errors/fields/cardMail.svg'
  )
  // Help
  const helpSecurityCode = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/help_securityCode.svg'
  )
  const helpSecurityCodeAmex = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/help_securityCode_amex.svg'
  )

  // Visibility
  const visibilityOn = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/visibility.svg'
  )
  const visibilityOff = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/visibilityOff.svg'
  )

  // Payment methods
  const bizum = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/bizum.svg'
  )
  const pse = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/pse.svg'
  )
  const franfinance = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/franfinance.svg'
  )
  const sepa = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/sepa.svg'
  )
  const efecty = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/efecty.svg'
  )
  const pagoefectivo = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/pagoefectivo.svg'
  )
  const pix = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/pix.svg'
  )
  const ip_wire = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/ip_wire.svg'
  )
  const avwu = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/avwu.svg'
  )
  const baloto = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/baloto.svg'
  )
  const movilRed = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/movil_red.svg'
  )
  const interrapidisimo = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/interrapidisimo.svg'
  )
  const sured = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/sured.svg'
  )
  const red_aval = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/red_aval.svg'
  )
  const exito_cash = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/exito_cash.svg'
  )
  const bancolombia = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/bancolombia.svg'
  )
  const nequi = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/nequi.svg'
  )
  const applePay = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/apple_pay.svg'
  )
  const paypal = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/paypal.svg'
  )
  const alma = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/alma.svg'
  )
  const cofidis = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/cofidis.svg'
  )
  const cofidis3x = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/cofidis_3x.svg'
  )
  const cofidis4x = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/cofidis_4x.svg'
  )
  const cofidisDfPay = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/cofidis_dfpay.svg'
  )
  const cofidisFracES = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/cofidis_frac_es.svg'
  )
  const cofidisLoanFR = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/cofidis_loan_fr.svg'
  )
  const cofidisLoanBE = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/cofidis_loan_be.svg'
  )
  const cofidisLoanCB = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/cofidis_loan_cb.svg'
  )
  const cofidisLoanES = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/cofidis_loan_es.svg'
  )
  const cofidisLoanIT = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/cofidis_loan_it.svg'
  )
  const cofidisPayFR = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/cofidis_pay_fr.svg'
  )
  const sicrediBoleto = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/sicredi_boleto.svg'
  )
  const bradescoBoleto = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/bradesco.svg'
  )
  const sicoobBoleto = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/sicoob.svg'
  )
  const payConiq = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/payconiq.svg'
  )
  const upi = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/upi.svg'
  )
  const multibanco = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/multibanco.svg'
  )
  const mbWay = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/mbWay.svg'
  )
  const webPayPlus = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/web_pay_plus.svg'
  )
  const googlePay = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/google_pay.svg'
  )
  const floa = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/floa.svg'
  )
  const floa3x = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/floa_3x.svg'
  )
  const floa4x = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/floa_4x.svg'
  )
  const floa10x = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/floa_10x.svg'
  )
  const floaPayLater = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/floa_paylater.svg'
  )
  const transfiya = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/transfiya.svg'
  )

  // Compact icons
  const applePayCompact = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/compact/apple_pay.svg'
  )
  const googlePayCompact = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/compact/google_pay.svg'
  )

  const alinea = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/alinea.svg'
  )

  const auchan = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/auchan.svg'
  )

  const boulanger = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/boulanger.svg'
  )

  const norauto = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/norauto.svg'
  )

  const oney3x4x = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/oney_3x_4x.svg'
  )

  const oney10x12x = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/oney_10x_12x.svg'
  )

  const oneyEnseigne = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/oney_enseigne.svg'
  )

  const oneyPayLater = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/oney_paylater.svg'
  )

  const picWic = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/picwic.svg'
  )

  const oney = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/oney.svg'
  )

  const cashLatam = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/cash_latam.svg'
  )

  const cvco = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/cvco.svg'
  )

  const alipayPlus = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/alipay_plus.svg'
  )

  const akulakuPayLater = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/akulaku_paylater.svg'
  )

  const alipayCN = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/alipay_cn.svg'
  )

  const alipayHK = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/alipay_hk.svg'
  )

  const billease = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/billease.svg'
  )

  const boost = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/boost.svg'
  )

  const bpi = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/bpi.svg'
  )

  const dana = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/dana.svg'
  )

  const gcash = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/gcash.svg'
  )

  const kakaopay = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/kakaopay.svg'
  )

  const kredivoID = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/kredivo_id.svg'
  )

  const maya = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/maya.svg'
  )

  const mpay = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/mpay.svg'
  )

  const rabbitLinePay = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/rabbit_line_pay.svg'
  )

  const tng = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/tng.svg'
  )

  const trueMoney = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/truemoney.svg'
  )

  const wechatPay = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/wechatpay.svg'
  )

  const toss = await import(
    /* webpackChunkName: "kr-assets" */ '@/assets/images/icons/regular/payment_methods/toss.svg'
  )

  return Promise.resolve({
    regular: {
      branded: {
        KADEOS_CULTURE: ensureUniqId(regularBrandKadeosCulture.default),
        KADEOS_GIFT: ensureUniqId(regularBrandKadeosGift.default),
        TUYA: ensureUniqId(regularBrandTuya.default),
        CARNET: ensureUniqId(regularBrandCarnet.default),
        CONECS: ensureUniqId(regularBrandConecs.default),
        DINERS: ensureUniqId(regularBrandDiners.default),
        SODEXO: ensureUniqId(regularBrandSodexo.default),
        EDENRED_CO: ensureUniqId(regularBrandEdenredCo.default),
        EDENRED_TR: ensureUniqId(regularBrandEdenredCo.default),
        EDENRED_TC: ensureUniqId(regularBrandEdenredCo.default),
        EDENRED_SC: ensureUniqId(regularBrandEdenredCo.default),
        EDENRED_EC: ensureUniqId(regularBrandEdenredCo.default),
        CHQ_DEJ: ensureUniqId(regularBrandChqDej.default),
        APETIZ: ensureUniqId(regularBrandApetiz.default),
        MASTERCARD: ensureUniqId(regularBrandMastercard.default),
        MASTERCARD_DEBIT: ensureUniqId(regularBrandMastercardDebit.default),
        AMEX: ensureUniqId(regularBrandAmex.default),
        CB: ensureUniqId(regularBrandCb.default),
        MAESTRO: ensureUniqId(regularBrandMaestro.default),
        BANCONTACT: ensureUniqId(regularBrandBanContact.default),
        VISA: ensureUniqId(regularBrandVisa.default),
        VISA_DEBIT: ensureUniqId(regularBrandVisaDebit.default),
        VISA_CREDIT: ensureUniqId(regularBrandVisa.default),
        VISA_ELECTRON: ensureUniqId(regularBrandVisaElectron.default),
        VPAY: ensureUniqId(regularBrandVpay.default),
        CABAL: ensureUniqId(regularBrandCabalDebit.default),
        CABAL_DEBIT: ensureUniqId(regularBrandCabalDebit.default),
        DCC: ensureUniqId(regularBrandDcc.default),
        CMR: ensureUniqId(regularBrandCmr.default),
        DISCOVER: ensureUniqId(regularBrandDiscover.default),
        OSB: ensureUniqId(regularBrandOsb.default),
        PRV_SMART_CARD: ensureUniqId(regularBrandPrv.default),
        PRV_OPT: ensureUniqId(regularBrandPrvOpt.default),
        'E-CARTEBLEUE': ensureUniqId(regularBrandECarteBleue.default),
        JCB: ensureUniqId(regularBrandJcb.default),
        NARANJA: ensureUniqId(regularBrandNaranja.default),
        AURA: ensureUniqId(regularBrandAura.default),
        ELO: ensureUniqId(regularBrandElo.default),
        HIPER: ensureUniqId(regularBrandHiper.default),
        HIPERCARD: ensureUniqId(regularBrandHipercard.default),
        PRV_BDP: ensureUniqId(regularBrandPrvBdp.default),
        PRV_SOC: ensureUniqId(regularBrandPrvSocredo.default)
      }
    },
    outline: {
      branded: {
        DINERS: ensureUniqId(outlineBrandDiners.default)
      }
    },
    error: {
      pan: ensureUniqId(errorCard.default),
      expiryDate: ensureUniqId(errorExpiry.default),
      securityCode: ensureUniqId(errorSecurityCode.default),
      identityDocumentNumber: ensureUniqId(errorIdentityDocumentNumber.default),
      cardHolderName: ensureUniqId(errorCardHolderName.default),
      cardHolderMail: ensureUniqId(errorCardHolderMail.default),
      branded: {
        KADEOS_CULTURE: ensureUniqId(errorBrandKadeosCulture.default),
        KADEOS_GIFT: ensureUniqId(errorBrandKadeosGift.default),
        TUYA: ensureUniqId(errorBrandTuya.default),
        CARNET: ensureUniqId(errorBrandCarnet.default),
        CONECS: ensureUniqId(errorBrandConecs.default),
        DINERS: ensureUniqId(errorBrandDiners.default),
        SODEXO: ensureUniqId(errorBrandSodexo.default),
        EDENRED_CO: ensureUniqId(errorBrandEdenredCo.default),
        CHQ_DEJ: ensureUniqId(errorBrandChqDej.default),
        APETIZ: ensureUniqId(errorBrandApetiz.default),
        MASTERCARD: ensureUniqId(errorBrandMastercard.default),
        MASTERCARD_DEBIT: ensureUniqId(errorBrandMastercardDebit.default),
        AMEX: ensureUniqId(errorBrandAmex.default),
        CB: ensureUniqId(errorBrandCb.default),
        MAESTRO: ensureUniqId(errorBrandMaestro.default),
        BANCONTACT: ensureUniqId(errorBrandBanContact.default),
        VISA: ensureUniqId(errorBrandVisa.default),
        VISA_DEBIT: ensureUniqId(errorBrandVisaDebit.default),
        VISA_CREDIT: ensureUniqId(errorBrandVisa.default),
        VISA_ELECTRON: ensureUniqId(errorBrandVisaElectron.default),
        VPAY: ensureUniqId(errorBrandVpay.default),
        CABAL: ensureUniqId(errorBrandCabalDebit.default),
        CABAL_DEBIT: ensureUniqId(errorBrandCabalDebit.default),
        DCC: ensureUniqId(errorBrandDcc.default),
        CMR: ensureUniqId(errorBrandCmr.default),
        DISCOVER: ensureUniqId(errorBrandDiscover.default),
        OSB: ensureUniqId(errorBrandOsb.default),
        PRV_SMART_CARD: ensureUniqId(errorBrandPrv.default),
        'E-CARTEBLEUE': ensureUniqId(errorBrandECarteBleue.default),
        JCB: ensureUniqId(errorBrandJcb.default),
        NARANJA: ensureUniqId(errorBrandNaranja.default),
        AURA: ensureUniqId(errorBrandAura.default),
        ELO: ensureUniqId(errorBrandElo.default),
        HIPER: ensureUniqId(errorBrandHiper.default),
        HIPERCARD: ensureUniqId(errorBrandHipercard.default),
        PRV_BDP: ensureUniqId(errorBrandPrvBdp.default),
        PRV_SOC: ensureUniqId(errorBrandPrvSocredo.default)
      }
    },
    help: {
      securityCode: ensureUniqId(helpSecurityCode.default),
      securityCodeAmex: ensureUniqId(helpSecurityCodeAmex.default)
    },
    visibility: {
      on: ensureUniqId(visibilityOn.default),
      off: ensureUniqId(visibilityOff.default)
    },
    paymentMethods: {
      regular: {
        BIZUM: ensureUniqId(bizum.default),
        PSE: ensureUniqId(pse.default),
        FRANFINANCE: ensureUniqId(franfinance.default),
        FRANFINANCE_3X: ensureUniqId(franfinance.default),
        FRANFINANCE_4X: ensureUniqId(franfinance.default),
        SEPA: ensureUniqId(sepa.default),
        EFECTY: ensureUniqId(efecty.default),
        PAGOEFECTIVO: ensureUniqId(pagoefectivo.default),
        PIX: ensureUniqId(pix.default),
        IP_WIRE: ensureUniqId(ip_wire.default),
        IP_WIRE_INST: ensureUniqId(ip_wire.default),
        AVWU: ensureUniqId(avwu.default),
        BALOTO: ensureUniqId(baloto.default),
        RED_AVAL: ensureUniqId(red_aval.default),
        INTERRAPIDISIMO: ensureUniqId(interrapidisimo.default),
        SURED: ensureUniqId(sured.default),
        MOVIL_RED: ensureUniqId(movilRed.default),
        EXITO_CASH: ensureUniqId(exito_cash.default),
        BANCOLOMBIA_IP: ensureUniqId(bancolombia.default),
        NEQUI: ensureUniqId(nequi.default),
        APPLE_PAY: ensureUniqId(applePay.default),
        PAYPAL: ensureUniqId(paypal.default),
        PAYPAL_SB: ensureUniqId(paypal.default),
        PAYPAL_BNPL: ensureUniqId(paypal.default),
        PAYPAL_BNPL_SB: ensureUniqId(paypal.default),
        ALMA: ensureUniqId(alma.default),
        ALMA_2X: ensureUniqId(alma.default),
        ALMA_3X: ensureUniqId(alma.default),
        ALMA_4X: ensureUniqId(alma.default),
        ALMA_10X: ensureUniqId(alma.default),
        ALMA_12X: ensureUniqId(alma.default),
        COFIDIS: ensureUniqId(cofidis.default),
        COFIDIS_DFPAY_FR: ensureUniqId(cofidisDfPay.default),
        COFIDIS_FRAC_ES: ensureUniqId(cofidisFracES.default),
        COFIDIS_LOAN_BE: ensureUniqId(cofidisLoanBE.default),
        COFIDIS_LOAN_CB: ensureUniqId(cofidisLoanCB.default),
        COFIDIS_LOAN_ES: ensureUniqId(cofidisLoanES.default),
        COFIDIS_LOAN_FR: ensureUniqId(cofidisLoanFR.default),
        COFIDIS_LOAN_IT: ensureUniqId(cofidisLoanIT.default),
        COFIDIS_PAY_FR: ensureUniqId(cofidisPayFR.default),
        COFIDIS_4X_ES: ensureUniqId(cofidis4x.default),
        COFIDIS_4X_FR: ensureUniqId(cofidis4x.default),
        COFIDIS_3X_BE: ensureUniqId(cofidis3x.default),
        COFIDIS_3X_FR: ensureUniqId(cofidis3x.default),
        SICREDI_BOLETO: ensureUniqId(sicrediBoleto.default),
        BRADESCO_BOLETO: ensureUniqId(bradescoBoleto.default),
        SICOOB_BOLETO: ensureUniqId(sicoobBoleto.default),
        PAYCONIQ: ensureUniqId(payConiq.default),
        UPI: ensureUniqId(upi.default),
        MULTIBANCO: ensureUniqId(multibanco.default),
        MB_WAY: ensureUniqId(mbWay.default),
        WEBPAY_PLUS: ensureUniqId(webPayPlus.default),
        GOOGLEPAY: ensureUniqId(googlePay.default),
        ALINEA: ensureUniqId(alinea.default),
        ALINEA_SB: ensureUniqId(alinea.default),
        AUCHAN: ensureUniqId(auchan.default),
        AUCHAN_SB: ensureUniqId(auchan.default),
        BOULANGER: ensureUniqId(boulanger.default),
        BOULANGER_SB: ensureUniqId(boulanger.default),
        NORAUTO: ensureUniqId(norauto.default),
        NORAUTO_SB: ensureUniqId(norauto.default),
        ONEY: ensureUniqId(oney.default),
        ONEY_3X_4X: ensureUniqId(oney3x4x.default),
        ONEY_10X_12X: ensureUniqId(oney10x12x.default),
        ONEY_ENSEIGNE: ensureUniqId(oneyEnseigne.default),
        ONEY_PAYLATER: ensureUniqId(oneyPayLater.default),
        PICWIC: ensureUniqId(picWic.default),
        PICWIC_SB: ensureUniqId(picWic.default),
        CASH_LATAM: ensureUniqId(cashLatam.default),
        CVCO: ensureUniqId(cvco.default),
        ALIPAY_PLUS: ensureUniqId(alipayPlus.default),
        AKULAKU_PAYLATER: ensureUniqId(akulakuPayLater.default),
        ALIPAY_CN: ensureUniqId(alipayCN.default),
        ALIPAY_HK: ensureUniqId(alipayHK.default),
        BILLEASE: ensureUniqId(billease.default),
        BOOST: ensureUniqId(boost.default),
        BPI: ensureUniqId(bpi.default),
        DANA: ensureUniqId(dana.default),
        GCASH: ensureUniqId(gcash.default),
        KAKAOPAY: ensureUniqId(kakaopay.default),
        KREDIVO_ID: ensureUniqId(kredivoID.default),
        MAYA: ensureUniqId(maya.default),
        MPAY: ensureUniqId(mpay.default),
        RABBIT_LINE_PAY: ensureUniqId(rabbitLinePay.default),
        TNG: ensureUniqId(tng.default),
        TRUEMONEY: ensureUniqId(trueMoney.default),
        FLOA: ensureUniqId(floa.default),
        FLOA_3X: ensureUniqId(floa3x.default),
        FLOA_4X: ensureUniqId(floa4x.default),
        FLOA_10X: ensureUniqId(floa10x.default),
        FLOA_PAYLATER: ensureUniqId(floaPayLater.default),
        TRANSFIYA: ensureUniqId(transfiya.default),
        WECHATPAY: ensureUniqId(wechatPay.default),
        TOSS: ensureUniqId(toss.default)
      },
      compact: {
        APPLE_PAY: ensureUniqId(applePayCompact.default),
        GOOGLEPAY: ensureUniqId(googlePayCompact.default)
      }
    }
  })
}
