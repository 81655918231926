import { pick, clone } from 'underscore'

import Events from '@/configuration/Events'
import fieldTypes from '@/configuration/sources/FieldTypes.yml'
import synchronization from '@/configuration/sources/Synchronization.yml'
import { logSentry, logSentryException } from '@/common/util/sentry'
import { DomValidationError } from '@/common/errors/DomValidationError'
export default class Blender {
  constructor($locator) {
    this.$locator = $locator
    this.cardForm = {}
    this.ghostReady = false
    this.rendered = false
    this.bootInProgress = false
    this.bootQueue = []
    this.relocation = false

    this.registerListeners()
  }

  registerListeners() {
    const $locator = this.$locator
    const { $store } = this.$locator

    $locator.$bus.$on(Events.krypton.message.echo, msg => {
      // Resend the echo to let know the iframes host received it
      const { source } = msg
      $locator.proxy.send(
        $locator.storeFactory.create('echo', msg),
        source === 'redirect'
      )
      if (source && source !== 'redirect') this.iframeReady(msg)
      if (source === 'redirect') this.syncRedirect()
    })

    // Reset listener
    $locator.$bus.$on(Events.krypton.destroy, message => {
      logSentry('Reset', 'Form removed')
      this.cardForm = {}
      this.ghostReady = false
      this.rendered = false
    })

    /**
     * Ghost relocation (popin)
     *
     * @param {{ slaves: boolean }}
     */
    $locator.$bus.$on(Events.iframe.relocation, ({ slaves }) => {
      $locator.allReadyQueue.reset()
      if (slaves) {
        for (const formId in this.cardForm) {
          this.cardForm[formId].slaves = []
        }
      }
      for (const formId in this.cardForm) {
        this.cardForm[formId].allReady = false
      }
      $store.dispatch('update', { allIFramesReady: false })
      this.relocation = true
    })

    // Card form registry initialization
    $locator.$bus.$on(Events.krypton.form.new, ({ formId }) => {
      this.initFormData(formId)
    })
  }

  /**
   * Manages the form initialization
   */
  boot(spaInit = false, $renderElements = null, initialBoot = false) {
    return new Promise((resolve, reject) => {
      if (this.bootInProgress) {
        this.putBootOnHold(spaInit, resolve, reject)
      } else {
        this.bootInProgress = true
        const {
          iframeController,
          staticFinder,
          timerManager: timerMan,
          domReader,
          domValidator,
          tokenReader,
          dna,
          riskManager,
          formRenderer,
          logger,
          checker,
          $store,
          formCleaner,
          configurationHandler
        } = this.$locator

        // If the ghost has been created but the form it's not rendered, reset the elements
        // The form has been removed from the dom without calling removeForms
        if (
          this.rendered &&
          this.ghostReady &&
          !$store.getters.isFormRendered()
        ) {
          formCleaner.resetDom()
        }

        timerMan.register(
          'start',
          'parsingConfiguration',
          'Parsing configuration'
        )
        // Collect basic configuration: address, domain, paths
        staticFinder.detectAddresses()
        timerMan.register('end', 'parsingConfiguration')

        timerMan.register('start', 'readingNodes', 'Reading nodes')

        // Parse the dom to collect KR attributes
        try {
          // collect required attributes for validation
          domReader.preCollect()
          if ($store.state.spaMode && initialBoot) {
            resolve()
            this.checkBootQueue()
            return
          }
          /**
           *  We don't want to validate again if
           *  - the form has already been rendered (i.e. coming from setFormToken)
           *  - its the initial load of a spa application (but not the spaInit boot)
           */
          if (
            !$store.getters.isFormRendered() &&
            (!$store.state.spaMode || spaInit)
          ) {
            // validation on provided element(s) or on the entire body otherwise
            const $validElements = domValidator.validateElements(
              $renderElements ?? [document.querySelector('body')],
              initialBoot // if its the first boot (from HostApp) we want to allow having an initial empty DOM and boot again with a setFormToken call
            )

            // add kr-element attribute if necessary
            if ($renderElements) domValidator.addKRElement($validElements)
          }

          domReader
            .collect(spaInit)
            .then(state => {
              // Creates ghost iframe (when it's possible) - except if it's spaMode and
              // it's the initial boot
              return iframeController
                .createGhost(state.spaMode && !spaInit)
                .then(() => state)
            })
            .then(state => {
              timerMan.register('end', 'readingNodes')
              logSentry('Boot', 'Ghost iframe created + dom read')
              timerMan.register('start', 'parsingDNA', 'Parsing the DNA')
              // Decode form token
              return tokenReader.decode(state.formToken)
            })
            .then(rawDna => {
              logSentry('Boot', 'Token decoded')
              // Parse dna
              return dna.parse(rawDna, spaInit)
            })
            .then(parsedDna => {
              timerMan.register('end', 'parsingDNA')
              logSentry('Boot', 'DNA parsed')
              // Setup whitelabel and theme store configurations
              configurationHandler.setupInitialConfig()
              // Load the risk analyser
              riskManager.load(parsedDna.riskAnalyser)
              // If it's spa mode, the form is only rendered with add/attachForm/renderElements
              if (this.shouldSkipFormRenderer(spaInit))
                return Promise.resolve(false)
              // Render
              timerMan.register('start', 'formRender', 'Rendering the form')
              return formRenderer.render()
            })
            .then(rendered => {
              // If there is no form, set the flag as false, expect if it's spaMode
              this.rendered = rendered
              timerMan.register('end', 'formRender')
              if (rendered) logSentry('Boot', 'Form rendered')
              checker.checkParams('form')
              this.bootInProgress = false
              logSentry('Boot', 'Resolved')
              resolve()
              // Check if there is any pending boot
              this.checkBootQueue()
              // Check if there is a mismatch of dom elements during the boot
              this.checkDomElementsMismatch()
              /**
               * KJS-3074: Remove finally because of issues with Laposte integration
               */
              this.bootInProgress = false
            })
            .catch(error => {
              logger.logPromiseError(error, 'host/Blender.boot')
              reject(error)
              // Even with an error, check if there are pending boots
              this.checkBootQueue()
            })
        } catch (error) {
          if (error instanceof DomValidationError) {
            const { dispatch, state } = this.$locator.$store
            dispatch('error', {
              errorCode: error.message,
              metadata: { console: true }
            })
            if (state.testKeys)
              this.$locator.$bus.$emit(Events.krypton.data.errorAlert)
          }
          resolve()
          // Even with an error, reset flags and check if there are pending boots
          this.checkBootQueue()
          logSentryException(error, 'host/Blender.boot')
        }
      }
    })
  }

  /**
   * Initialize ready status and slaves list when a new formId is detected.
   *
   * @param {string} formId
   * @since KJS-3458
   */
  initFormData(formId) {
    if (!(formId in this.cardForm)) {
      this.cardForm[formId] = {
        slaves: clone(fieldTypes.iframe),
        allReady: false
      }
    }
  }

  /**
   * Receives the iframes echo messages and checks if everything is ready
   */
  iframeReady(echo) {
    const $store = this.$locator.$store
    if ($store.state.isUnitaryTest) return

    const iframe = echo.source === 'ghost' ? 'ghost' : echo.fieldName
    this.$locator.timerManager.register('end', `${iframe}IframeLoad`)

    this.initFormData(echo.formId)
    // We check if there is any iframe pending to load
    const slaves = this.cardForm[echo.formId].slaves
    const slaveIndex = slaves.indexOf(echo.fieldName)
    if (~slaveIndex) slaves.splice(slaveIndex, 1)
    if (!slaves.length && this.ghostReady) this.allIframesReady(echo.formId)

    // When the ghost is loaded we can run the ghostQueue
    if (echo.source == 'ghost') {
      logSentry('Boot', 'Ghost iframe ready')
      this.$locator.ghostQueue.sendAccumulatedMessages()
      // Ghost relocation case
      if (!slaves.length && !this.ghostReady) this.allIframesReady(echo.formId)
      this.ghostReady = true
      this.checkBootQueue()
    }
  }

  /**
   * Performs the necessary action once all the iframes are ready
   */
  allIframesReady(formId) {
    if (!formId) return
    // Should be called only one single time
    this.initFormData(formId)
    if (this.cardForm[formId].allReady) return
    const { proxy, storeFactory } = this.$locator
    this.cardForm[formId].allReady = true
    this.$locator.pluginManager.load()

    const updateObj = { allIFramesReady: true }
    if (performance && window && performance.now) {
      // Measure performance (load time)
      updateObj.timeEnd = performance.now()
    }

    logSentry('Boot', 'All iframes ready')
    this.$locator.$bus.$emit(Events.krypton.iframes.allReady, { formId })
    // If it's a relocation (slaves) - force the global store sync
    if (this.relocation) proxy.send(storeFactory.create('globalSync', {}))
    this.$locator.$store.dispatch('update', updateObj)
    this.relocation = false
  }

  /**
   * KJS-1178: Consecutive async boots
   * In case the another boot is called before the current one is finished,
   * puts that on hold
   */
  putBootOnHold(param, resolve, reject) {
    logSentry('BootQueue', 'On hold - another boot in progress')
    this.bootQueue.push({
      method: 'boot',
      params: [param],
      resolve,
      reject
    })
  }

  /**
   * Checks if there are pending boot calls in the queue and run the first one
   */
  checkBootQueue() {
    logSentry(
      'BootQueue',
      `Checking boot queue - ${this.bootQueue.length} pending`
    )
    this.bootInProgress = false
    if (this.bootQueue.length) {
      const callInfo = this.bootQueue[0]
      this.bootQueue.shift()
      const params = callInfo.params
      logSentry('BootQueue', 'Calling next boot in queue')
      this[callInfo.method](...params)
        .then(callInfo.resolve)
        .catch(callInfo.reject)
    }
  }

  syncRedirect() {
    const { proxy, storeFactory, $store } = this.$locator
    logSentry('Redirect', `Syncing`)
    proxy.send(
      storeFactory.create('sync', {
        data: pick($store.state, ...synchronization.whiteList.redirect),
        origin: 'host'
      }),
      true
    )
  }

  /**
   * KJS-2984: Fix for some wrong integrations previously working
   *
   * Checks if there is a mismatch of dom elements during the boot
   * If so, forces a reset of the dom elements and reboots the form
   */
  checkDomElementsMismatch() {
    const { domReader, $store, formCleaner } = this.$locator
    const { getFormElement, hasSmartElements } = $store.getters

    const formElement = getFormElement()
    const smartElements = hasSmartElements()

    const hasDomElements = formElement || smartElements

    if (!domReader.hasDomElements && hasDomElements) {
      console.error(
        'Payment Form DOM elements have been added during the initialisation. It may create some interface flickering, You should add the DOM elements before the initialization, or after and call KR.attachForm() method. Do not forget to manage promises (using await or then() method)'
      )
      setTimeout(() => {
        formCleaner.resetDom()
        this.boot().then().catch()
      }, 0)
    }
  }

  /**
   *
   *  KJS-4435 !dna.hasAddedMainForm() : Previous condition was used to render
   *  or not main form we cannot proceed with rendering without the main form
   *  being create in store.
   *
   *  KJS-4120 !formRenderer.shouldRenderClone() : Rerender is necessary when
   *  we pass from a form with no clone to a form with clone.
   *
   * @returns {boolean}
   * @since KJS-4435
   */
  shouldSkipFormRenderer(spaInit) {
    const { dna, formRenderer, $store } = this.$locator

    return (
      $store.state.spaMode &&
      !spaInit &&
      (!dna.hasAddedMainForm() ||
        (formRenderer.isRendered() && !formRenderer.shouldRenderClone()))
    )
  }
}
