var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.hasDetached,
          expression: "!hasDetached",
        },
      ],
      staticClass: "kr-sp-panel-wrapper",
    },
    [
      _c("label", { staticClass: "kr-sp-panel-wrapper__label" }, [
        _vm._v(
          _vm._s(
            _vm._f("colon")(_vm._f("capitalize")(_vm.headerLabel), _vm.language)
          )
        ),
      ]),
      _c(
        "div",
        { ref: "content", staticClass: "kr-sp-panel-content" },
        [
          _c("SplitPaymentPanel", {
            attrs: { "installment-options": _vm.options },
            on: { "option-selected": _vm.onOptionSelected },
          }),
        ],
        1
      ),
      _c("SplitPaymentPanelSeparator"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }