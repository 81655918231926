<template lang="pug">
  span.kr-sp-button-label(v-html="content")
    //- Render as:
    //- span.kr-sp-button-amount Pay {{ <formattedAmount> }}
    //- out of
    //- span.kr-sp-button-total {{ <formattedTotal> }}
</template>

<script>
import { mapGetters, mapState } from 'vuex'

/**
 * Display formatted button label in PaymentButton.
 *
 * @since KJS-4366
 */
export default {
  name: 'SplitPaymentButtonLabel',
  computed: {
    ...mapGetters(['firstAmount', 'getAmountLabel', 'translate']),
    ...mapState(['totalAmountLabel']),
    content() {
      const translation = this.translate('split_payment_button_label')
      const firstAmount = this.getAmountLabel(this.firstAmount)

      return translation
        .replace(
          /(?:\[AMOUNT\]\s+)(.+)\s+\[TOTAL\]/,
          `[AMOUNT] <span class="kr-sp-button-total">$1 ${this.totalAmountLabel}</span>`
        )
        .replace(
          '[AMOUNT]',
          `<span class="kr-sp-button-amount">${firstAmount}</span>`
        )
    }
  }
}
</script>
