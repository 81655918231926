<template lang="pug">
button.kr-smart-form-modal-button(
  type="button",
  v-html="payBtnHTML",
  @click="open()",
  :class="{'kr-loading': loading, 'kr-disabled': disabledForm}"
  :style="styles"
  :disabled="isDisabled"
)
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Events from '@/configuration/Events'
import { SmartFormClickMixin } from '@/host/components/mixins/SmartFormClick'

export default {
  name: 'SmartFormModalButton',
  mixins: [SmartFormClickMixin],
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState(['payBtnHTML', 'disabledForm']),
    ...mapGetters([
      'isDeadEndPaymentMethod',
      'isSmartButtonActive',
      'hasValidToken'
    ]),
    ...mapState({
      defaultMethod: state => state.smartForm.defaultMethod,
      modalOpen: state => state.smartForm.isOpen,
      errorCode: state => state.error.errorCode,
      buttonConfig: state => state.form.button
    }),
    styles() {
      return {
        color: this.isDisabled
          ? this.buttonConfig.disabled.color
          : this.buttonConfig.color,
        backgroundColor: this.isDisabled
          ? this.buttonConfig.disabled.backgroundColor
          : this.buttonConfig.backgroundColor,
        borderColor: this.isDisabled
          ? this.buttonConfig.disabled.borderColor
          : this.buttonConfig.borderColor
      }
    },
    isDisabled() {
      return !this.hasValidToken
    }
  },
  watch: {
    modalOpen(val) {
      if (val && !this.isSmartButtonActive) this.loading = true
      else if (!val && !this.isDeadEndPaymentMethod) this.loading = false
    },
    errorCode(val) {
      if (val && !this.modalOpen) this.loading = false
    }
  },
  created() {
    this.startSubscriptions()
  },
  methods: {
    ...mapActions(['closeMethod']),
    startSubscriptions() {
      this.$busOn(Events.krypton.popin.open, message => {
        this.open()
      })
    },
    async open() {
      if (!this.disabledForm) {
        if (await this.interruptsExecution(null, 'openPopin')) return false
        if (!this.isSmartButtonActive) {
          this.loading = true
        }
        this.closeMethod()
        this.$emit('open')
      }
    }
  }
}
</script>
