import { Collection, InstallmentSchedule } from '@/common/model/'

const PRIVATE_CONSTRUCTOR_KEY = Symbol()

/**
 * Used to ensure a correct installment options collection is passed to
 * SplitPaymentPanel component. It cannot be instanciated with new operator.
 * Only the factory should be used in order to validate the data but also
 * sanitize it and sort options by number of installments ascending.

 * @since KJS-4363
 */
export default class InstallmentOptionCollection extends Collection {
  // Factory -------------------------------------------------------------------
  static create(collection) {
    if (collection instanceof InstallmentOptionCollection) return collection
    if (!Array.isArray(collection)) {
      throw new TypeError(
        `InstallmentOptionCollection::create: Invalid parameter, collection is expected to be an Array`
      )
    }

    this._validate(collection)

    return new InstallmentOptionCollection(
      this._sanitize(collection),
      PRIVATE_CONSTRUCTOR_KEY
    )
  }

  static _validate(collection) {
    if (collection.length < 1 || collection.length > 3)
      throw new Error(
        `InstallmentOptionCollection.create: Invalid number of options: ${collection.length}; expect value to be between 1 and 3`
      )
    for (const idx in collection) {
      const installmentOption = collection[idx]
      const { id, schedules } = installmentOption
      const hasValidId = typeof id === 'string'
      if (!hasValidId)
        throw new TypeError(
          `InstallmentOptionCollection.create: Invalid parameter at index [${idx}], property id: ${id}`
        )
      const hasValidSchedules =
        Array.isArray(schedules) || schedules instanceof InstallmentSchedule
      if (!hasValidSchedules)
        throw new TypeError(
          `InstallmentOptionCollection.create: Invalid parameter at index [${idx}], property schedules: ${schedules}`
        )
    }
  }

  static _sanitize(collection) {
    return collection
      .map(installmentOption => {
        return {
          id: installmentOption.id,
          schedules: InstallmentSchedule.create(installmentOption.schedules)
        }
      })
      .sort((a, b) => a.schedules.length - b.schedules.length)
  }

  // Instance ------------------------------------------------------------------
  constructor(collection, $key) {
    if ($key !== PRIVATE_CONSTRUCTOR_KEY) {
      throw new Error(
        `InstallmentOptionCollection: cannot create instance using new operator`
      )
    }
    super(collection)
  }
}
