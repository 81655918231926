import GooglePayConfig from '@/configuration/sources/smartform/googlePay.yml'
import GooglePayManager from '@/ghost/service/GooglePayManager'
import paymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'
import { getGooglePayFormattedAmount } from '@/common/util/currencyFormatter'
import { isInsecureEnvironment } from '@/common/util/environment'
import { logSentryException } from '@/common/util/sentry'
import { getAllowedCardNetworks } from '@/ghost/utils/googlePaymentData'

export const getGooglePayState = () => {
  return {
    googlePay: {
      ready: false,
      testMode: false,
      simulator: {
        open: false
      }
    }
  }
}

export const googlePayActions = ($locator, app) => {
  return {
    // Called from host
    async setupGooglePayHost({
      state: { isUnitaryTest },
      getters,
      commit,
      dispatch
    }) {
      if (!getters.isGooglePayCompatible) {
        dispatch('removePaymentMethod', 'GOOGLEPAY')
        dispatch('removeDnaPaymentMethod', 'GOOGLEPAY')
      } else {
        if (getters.isGooglePaySimulator) {
          if (isUnitaryTest) return

          const module = await import(
            /* webpackChunkName: "kr-simulator-google-pay" */ '@/simulator/googlepay/Loader'
          )
          const loader = module.default
          loader($locator)
          commit('UPDATE', { googlePay: { ready: true, testMode: true } })
        } else {
          const { allReadyQueue, storeFactory } = $locator
          // Need to setup googlePay from the ghost see KJS-4025
          allReadyQueue.send(storeFactory.create('setupGooglePaySDK'))
        }
      }
    },
    // Called from ghost
    async setupGooglePaySDK({
      state: {
        testKeys,
        isUnitaryTest,
        dna,
        smartForm: { dnaCardBrands }
      },
      getters,
      commit,
      dispatch
    }) {
      if (!getters.isGooglePaySimulator) {
        if (isInsecureEnvironment() || isUnitaryTest) return

        await $locator.scriptLoader.loadScriptPromise(
          paymentMethodsConf.sources.GOOGLEPAY,
          'kr-google-pay'
        )
        const { askCvv, walletShippingAddress } =
          dna.smartForm?.GOOGLEPAY?.metadata

        const isReadyResult = await GooglePayManager.isReadyToPayCall({
          testMode: testKeys,
          cvcRequired: !!askCvv,
          dnaBrands: dnaCardBrands,
          shippingAddressRequired: !!walletShippingAddress
        })?.catch(error => ({ error, result: false }))

        if (!isReadyResult?.result) {
          dispatch('removePaymentMethod', 'GOOGLEPAY')
          dispatch('removeDnaPaymentMethod', 'GOOGLEPAY')

          logSentryException(isReadyResult.error, 'store/modules/googlePay')
          return
        }

        commit('UPDATE', { googlePay: { ready: true, testMode: testKeys } })
      }
    },
    startGooglePayPayment({ state, dispatch, commit, getters }) {
      if (isInsecureEnvironment() && !getters.isGooglePaySimulator) {
        dispatch('error', {
          errorCode: 'CLIENT_514',
          paymentMethod: 'GOOGLEPAY'
        })
        return
      }

      if (!state.googlePay.ready) return

      dispatch('cleanError')
      commit('UPDATE', { smartForm: { activeMethod: 'GOOGLEPAY' } })

      const { gateway, gatewayMerchantId, askCvv, walletShippingAddress } =
        state.dna.smartForm?.GOOGLEPAY?.metadata || {}
      const { domain, protocol } = state.merchant
      const merchantOrigin = `${protocol}//${domain}`

      $locator.googlePayManager.start({
        merchantName: state.dna.shopName,
        merchantId: GooglePayConfig.merchantId,
        merchantOrigin,
        gateway,
        gatewayMerchantId,
        cvcRequired: !!askCvv,
        countryCode: state.country,
        currencyCode: state.currency,
        totalPrice: getGooglePayFormattedAmount(state.amount),
        dnaBrands: state.smartForm.dnaCardBrands,
        testMode: state.googlePay.testMode,
        testModeWithoutSimulator: getters.googlePayTestModeWithoutSimulator,
        shippingAddressRequired: !!walletShippingAddress
      })
    },
    openGooglePaySimulator({ commit }) {
      commit('UPDATE', {
        googlePay: { simulator: { open: true } }
      })
    },
    closeGooglePaySimulator({ commit }) {
      commit('UPDATE', {
        googlePay: { simulator: { open: false } }
      })
    }
  }
}
export const googlePayMutations = {}
export const googlePayGetters = {
  googlePayTestModeWithoutSimulator: ({ dna }) => {
    return !!dna?.customerEmail?.includes('@sandbox')
  },
  isGooglePaySimulator: (
    { testKeys },
    { googlePayTestModeWithoutSimulator }
  ) => {
    return testKeys && !googlePayTestModeWithoutSimulator
  },
  isGooglePayActive: (_state, { isActiveMethod }) =>
    isActiveMethod('GOOGLEPAY'),
  isGooglePayCompatible: ({ smartForm: { dnaCardBrands } }, { isFirefox }) => {
    const allowedCardBrands = getAllowedCardNetworks({
      dnaBrands: dnaCardBrands
    })
    return !isFirefox && !!allowedCardBrands.length
  }
}
