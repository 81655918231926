<script>
import { mapGetters, mapState } from 'vuex'
import { HtmlToVueCompiler } from '@/common/util/templateCompiler'

import SplitPaymentButtonLabel from '@/host/components/smartform/splitPayment/ButtonLabel'

/**
 * Introduced with SplitPayment button label.
 *
 * !! Stricly handle the visible rendering of the payment buttons !!
 * !! Logic should remain in parent components !!
 *
 * Initially used by KryptonPaymentButton & SinglePaymentButton.
 * It renders two things:
 * 1 - (always) The visible label of the button | By default based on the
 * store value payBtnHTML after compilation of the button.template
 * 2 - (optional) The html elements required for the spinner animation. Those
 * are different for each template, also no-theme has none.
 *
 * NB: the examples below are based on KryptonPaymentButton.
 * E.g: In the default case with neon, rendering shall look like:
 *
 * button.kr-payment-button(kr-resource='' type='button' tabindex='1' style='<styles...>' is='krypton-payment-button')
 *  span PAY &euro;100.00
 *  .kr-spinner
 *    i.kr-loader
 *
 * E.g 2: Whenever an installment option is selected (SplitPayment):
 * button.kr-payment-button(kr-resource='' type='button' tabindex='1' style='<styles...>' is='krypton-payment-button')
 *  SplitPaymentButtonLabel
 *  .kr-spinner
 *    i.kr-loader
 *
 * E.g 3: Default case with no-theme shall be rendered as:
 *
 * button.kr-payment-button(kr-resource='' type='button' tabindex='1' style='<styles...>' is='krypton-payment-button')
 *  span PAY &euro;100.00
 *
 * @since KJS-4366
 */
export default {
  name: 'PaymentButton',
  components: {
    SplitPaymentButtonLabel
  },
  computed: {
    ...mapGetters(['hasInstallmentSelected']),
    ...mapState(['payBtnHTML']),
    ...mapState({
      animation: ({ button }) => button.animation
    })
  },
  /**
   * @see KJS-4635 | Handle case where payBtnHtml is empty/falsy
   */
  render($createElement) {
    const compiler = new HtmlToVueCompiler($createElement)
    const label = this.hasInstallmentSelected
      ? compiler.render('<split-payment-button-label />')
      : this.payBtnHTML
      ? compiler.render(this.payBtnHTML)
      : ' '

    const children = [label]
    if (this.animation) {
      children.push(compiler.render(this.animation))
    }

    return $createElement(
      'button',
      {
        attrs: this.$attrs,
        on: this.$listeners
      },
      children
    )
  }
}
</script>
