<template lang="pug">
  .kr-help-button-wrapper(:class="[ isVisible ? 'kr-visible' : '', positionClass, isDisabled ? 'kr-disabled' : '' ]", v-if="isVisible")
      .kr-help-button(
        :style="css"
      )
          .kr-help-icon-wrapper(
            @click="openModal()"
            :class="[isInvalid ? 'kr-error' : '']"
            :style="{backgroundColor: css.color}"
          )
              span(
                :class="customIcon ? 'kr-custom-help-icon' : 'kr-help-icon'"
                v-html="customIcon || icon"
              )
      KryptonDialog(v-model="open", content-class="kr-help-button-wrapper")
        .kr-help-modal-wrapper(@click="closeModal()")
          .kr-help-modal-background
          .kr-help-modal(:style="[modalSize]", role="dialog")
            .kr-help-modal-header
                span.kr-help-modal-close-button(@click="closeModal()", v-html="closeIcon")
            .kr-help-modal-content(v-html="htmlContent")
</template>

<script>
import _ from 'underscore'
import Zepto from 'zepto-webpack'
import { mapState, mapGetters } from 'vuex'
import { dynamicMapState } from '@/common/util/store'

import Events from '@/configuration/Events'

import PreloadedAssets from '@/configuration/PreloadedAssets'
import { loadAssets } from '@/common/loader/assets'

import KryptonDialog from '@/host/components/controls/KryptonDialog'

/** MIXINS */
import ConfigurationMixin from '@/host/components/mixins/Configuration'
import { ClickOutsideMixin } from '@/host/components/mixins/ClickOutside'
import { MultipleBrandMixin } from '@/host/components/mixins/MultipleBrand'

export default {
  name: 'KryptonHelpButton',
  components: { KryptonDialog },
  mixins: [ConfigurationMixin, ClickOutsideMixin, MultipleBrandMixin],
  inject: ['context'],
  props: {
    fieldName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      open: false,
      modalOpen: false,
      htmlContent: '',
      modalSize: {},
      forcedHide: false,
      closeIcon: PreloadedAssets.close.close_icon
    }
  },
  computed: {
    ...mapGetters(['isFormPopin', 'isSmartForm', 'translate']),
    ...mapState(['language', 'fields', 'formToken']),
    ...mapState({ fieldIcons: state => state.fields.icons }),
    ...dynamicMapState('context.namespace', [
      'selectedBrand',
      'cardBrandHelpVisibility'
    ]),
    positionClass() {
      return 'kr-inner'
    },
    // Configuration
    css() {
      const field =
        this.fields[this.fieldName][this.isInvalid ? 'error' : 'default']
      return {
        backgroundColor: field.backgroundColor,
        color: field.iconColor
      }
    },
    isVisible() {
      if (this.forcedHide) return false
      if (this.fieldName === 'securityCode') return true
      if (this.fieldName === 'pan') {
        if (this.cardBrandHelpVisibility) return true
        if (this.cardBrandHelpVisibility === false) return false
        return this.hasMultipleBrands
      }
      return false
    },
    icon() {
      let icon = PreloadedAssets.help.help_icon
      if (this.css.color) {
        if (typeof PreloadedAssets.help.help_icon_style === 'function') {
          icon = PreloadedAssets.help.help_icon_style(icon, this.css)
        }
        icon = icon.replace(/fill=\"([^"]*)\"/g, `fill="${this.css.color}"`)
      }
      if (typeof icon === 'string') {
        const title = this.translate('cvv_help_icon_tootip')
        icon = icon.replace(/<title>(.*?)<\/title>/, `<title>${title}</title>`)
      }
      return icon
    },
    customIcon() {
      const url = this.fieldIcons?.help?.[this.fieldName]
      if (!url) return null

      const accessibleLabel =
        this.fieldName === 'pan'
          ? 'Help icon for PAN'
          : 'Help icon for Security Code'

      return this.urlToSvg(url, accessibleLabel)
    }
  },
  watch: {
    async language() {
      await this.setContent()
    },
    async selectedBrand() {
      if (this.fieldName === 'securityCode') await this.setContent()
    }
  },
  async created() {
    this.startSubscriptions()
    await this.setContent()
  },
  methods: {
    startSubscriptions() {
      const _this = this

      // Keyboard Esc detection
      document.addEventListener('keyup', evt => {
        if (evt.hasOwnProperty('key')) {
          if (evt.key === 'Escape' || evt.key === 'Esc') _this.closeModal()
        } else if (evt.keyCode === 27) {
          // No evt.key support
          _this.closeModal()
        }
      })

      // Events
      this.$busOn(Events.krypton.form.help.visibility, ({ visibility }) => {
        if (!visibility) this.forcedHide = true
      })
    },
    /**
     * @see KJS-2030  Add aria-hidden=true for accessibility
     */
    async setContent() {
      const fieldKey = this.fieldName === 'pan' ? 'pan' : 'cvv'

      if (fieldKey === 'pan' || fieldKey === 'cvv') {
        let firstKey = `${fieldKey}_help_1`
        let secKey = `${fieldKey}_help_2`

        if (this.selectedBrand === 'AMEX' && fieldKey === 'cvv') {
          firstKey = 'amex_cvv_help_1'
          secKey = 'amex_cvv_help_2'
        }

        const firstTrans = this.translate(firstKey)
        const secTrans = this.translate(secKey)

        let htmlContent = `
        <div class="kr-help-content">${firstTrans}</div>
        <div class="kr-help-content">${secTrans}</div>`

        if (fieldKey === 'cvv') {
          const { help } = await loadAssets()
          // If AMEX, use the specific image for the security code (See KJS-4028)
          const isAmex = this.selectedBrand === 'AMEX'
          const icon = isAmex ? help.securityCodeAmex : help.securityCode
          const className = isAmex ? 'kr-help-image-amex' : 'kr-help-image'
          htmlContent += `<div class="${className}" aria-hidden="true">${icon}</div>`
        }

        this.htmlContent = htmlContent
      }
    },
    openModal() {
      const _this = this
      this.setModalSize()
      if (this.isVisible) {
        this.open = true
        setTimeout(() => {
          _this.modalOpen = true
        }, 100)
      }
    },
    closeModal() {
      if (this.modalOpen) {
        this.open = false
        this.modalOpen = false
      }
    },
    setModalSize() {
      // If it's a popin form, fill the same space.
      if (this.isFormPopin && !this.isSmartForm) {
        let $embedded = Zepto('.kr-embedded')
        const hasLowerHeight = Zepto(window).height() <= $embedded.height()
        this.modalSize = {
          height: hasLowerHeight ? `100%` : `${$embedded.height()}px`,
          width: `${$embedded.width()}px`
        }
      }
    },
    urlToSvg(url, accessibleLabel) {
      return `
        <svg xmlns="http://www.w3.org/2000/svg" role="img" aria-label="${accessibleLabel}">
          <image href="${url}" width="100%" height="100%"></image>
        </svg>
      `
    }
  }
}
</script>
