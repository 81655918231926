import { logSentryException } from '@/common/util/sentry'
import Events from '@/configuration/Events'
import {
  getIsReadyToPayRequest,
  getGooglePaymentDataRequest
} from '@/ghost/utils/googlePaymentData'

export default class GooglePayManager {
  constructor({ $store, $bus, proxy, storeFactory }) {
    this.$store = $store
    this.$bus = $bus
    this.proxy = proxy
    this.storeFactory = storeFactory
  }

  static clientInstance = undefined

  static getClientInstance(testMode = true) {
    if (this.clientInstance) return this.clientInstance
    if (typeof window.google?.payments?.api?.PaymentsClient !== 'function')
      return undefined
    this.clientInstance = new google.payments.api.PaymentsClient({
      environment: testMode ? 'TEST' : 'PRODUCTION'
    })
    return this.clientInstance
  }

  static isReadyToPayCall({
    testMode,
    cvcRequired,
    dnaBrands,
    shippingAddressRequired
  }) {
    const client = GooglePayManager.getClientInstance(testMode)
    return client?.isReadyToPay(
      getIsReadyToPayRequest({
        cvcRequired,
        dnaBrands,
        shippingAddressRequired
      })
    )
  }

  /**
   *
   * @param {GooglePaymentParams} paymentParams
   */
  async start(paymentParams) {
    const { testMode } = paymentParams

    try {
      const result = await this.getGooglePayResult(paymentParams)

      // Google payment will be triggered from the GetPayloadInfos or the extra fields modal
      this.$bus.$emit(Events.krypton.message.getPayloadInfos, {
        payload: result,
        payloadType: 'GOOGLEPAY',
        testMode
      })
    } catch (googlePayError) {
      const googlePayErrorMessage =
        googlePayError?.message || googlePayError?.statusMessage

      // Check if the error is related to duplicate PaymentRequest UI
      const isDuplicatePaymentUIError =
        /Another PaymentRequest UI is already showing in a different tab or window./.test(
          googlePayErrorMessage
        )

      let error

      if (isDuplicatePaymentUIError) {
        error = {
          errorCode: 'CLIENT_603',
          paymentMethod: 'GOOGLEPAY'
        }
      } else {
        error = {
          errorCode: 'CLIENT_602',
          paymentMethod: 'GOOGLEPAY',
          metadata: { googlePayErrorMessage }
        }
      }

      this.$store.dispatch('error', error)

      logSentryException(googlePayError, 'ghost/service/GooglePayManager')
    }
  }

  /**
   * @param {GooglePaymentParams} paymentParams
   */
  async getGooglePayResult(paymentParams) {
    const { testMode, testModeWithoutSimulator } = paymentParams
    return testMode && !testModeWithoutSimulator
      ? this.getResultFromSimulator()
      : this.getResult(paymentParams, testMode)
  }

  getResultFromSimulator() {
    // This call is made from the ghost and the simulator is in the host
    // Need communication via proxy.send
    const result = new Promise((resolve, reject) => {
      this.$bus.$on(
        Events.krypton.message.googlePaySimulatorAction,
        actionInfo => {
          if (actionInfo.type === 'close') {
            this.proxy.send(this.storeFactory.create('closeGooglePaySimulator'))

            // Only unsubscribe on close, as it can receive multiple events before closing
            this.$bus.$off(Events.krypton.message.googlePaySimulatorAction)
            reject({ statusCode: 'CANCELED' })
            return
          }
          resolve(actionInfo.payload)
        }
      )
    })

    this.$store.dispatch('openGooglePaySimulator')
    return result
  }

  async getResult(paymentParams, testMode) {
    const client = GooglePayManager.getClientInstance(testMode)
    const result = await client.loadPaymentData(
      getGooglePaymentDataRequest(paymentParams)
    )
    return result
  }
}
